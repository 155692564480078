import { AuthorizationType, ItemHasChildrenType, ItemType } from '../types'

export const provinces = [
  'AB',
  'BC',
  'MB',
  'NB',
  'NL',
  'NS',
  'ON',
  'PE',
  'QC',
  'SK',
  'NT',
  'YT',
  'NU',
]

export const authorization: AuthorizationType = {
  '/formations/my-formations': null,
  '/formations/formation-enterprise': {
    read: ['Admin'],
    write: ['Admin'],
  },
  '/events/my-events': null,
  '/events/enterprise-event': {
    read: ['Admin'],
    write: ['Admin'],
  },
  '/member-benefits': {
    read: ['Utilisateur', 'Achat', 'Finance', 'Admin'],
  },
  '/entreprise/entreprise-profile': {
    read: ['Utilisateur', 'Achat', 'Finance', 'Admin'],
    write: ['Admin'],
  },
  '/entreprise/documents': {
    read: ['Utilisateur', 'Achat', 'Finance', 'Admin'],
    write: [],
  },
  '/entreprise/commandes': {
    read: ['Finance'],
    write: ['Finance'],
  },
  '/entreprise/communications': {
    read: ['Admin'],
    write: ['Admin'],
  },
  '/entreprise/users': {
    read: ['Utilisateur', 'Achat', 'Finance', 'Admin'],
    write: ['Utilisateur', 'Achat', 'Finance', 'Admin'],
  },
  '/profile/personal-informations': null,
  '/': null,
  '/profile/preferences': {
    read: ['Utilisateur', 'Achat', 'Finance', 'Admin'],
    write: ['Admin'],
  },
  '/profile/commandes':  null,
  '/forbidden': null,
}
export const itemsSubBar: (ItemType | ItemHasChildrenType)[] = [
  {
    item: 'events.myEvents',
    label: 'eventsItem',
    roles: null,
    mainRoute: '/events/my-events',
    children: [
      {
        label: 'events.myEvents',
        item: 'events.myEvents',
        mainRoute: '/events/my-events',
      },
      {
        label: 'events.enterpriseEvent',
        item: 'events.enterpriseEvent',
        mainRoute: '/events/enterprise-event',
      },
    ],
  },
  {
    item: 'formations.my-formations',
    label: 'formationsItem',
    roles: null,
    mainRoute: '/formations/my-formations',
    children: [
      {
        label: 'formations.my-formations',
        item: 'formations.my-formations',
        mainRoute: '/formations/my-formations',
      },
      {
        label: 'formations.formation-enterprise',
        item: 'formations.formation-enterprise',
        mainRoute: '/formations/formation-enterprise',
      },
    ],
  },
  {
    item: 'memberBenefits',
    label: 'memberBenefitsItem',
    roles: ['Admin', 'Finance', 'Utilisateur', 'Achat'],
    mainRoute: '/member-benefits',
  },
  {
    item: 'entreprise',
    label: 'entrepriseItem',
    roles: ['Admin', 'Finance', 'Utilisateur', 'Achat'],
    children: [
      {
        label: 'entreprise.entrepriseProfile',
        item: 'entreprise.entrepriseProfile',
        mainRoute: '/entreprise/entreprise-profile',
      },
      {
        label: 'entreprise.users',
        item: 'entreprise.users',
        mainRoute: '/entreprise/users',
      },
      {
        label: 'entreprise.communications',
        item: 'entreprise.communications',
        mainRoute: '/entreprise/communications',
      },
      {
        label: 'entreprise.documents',
        item: 'entreprise.documents',
        mainRoute: '/entreprise/documents',
      },
      {
        label: 'entreprise.orders',
        item: 'entreprise.orders',
        mainRoute: '/entreprise/commandes',
      },
    ],
  },
  {
    item: 'myProfile',
    label: 'myProfileItem',
    roles: null,
    children: [
      {
        label: 'myProfile.personalInformations',
        item: 'myProfile.personalInformations',
        mainRoute: '/profile/personal-informations',
        otherRoutes: ['/'],
      },
      /* {
        label: 'myProfile.preferences',
        item: 'myProfile.preferences',
        mainRoute: '/profile/preferences',
      }, */
      {
        label: 'myProfile.orders',
        item: 'myProfile.orders',
        mainRoute: '/profile/commandes',
      },
    ],
  },
]

export const listRoles = [
  { id: 'Utilisateur', name: 'Utilisateur' },
  { id: 'Admin', name: 'Administratif' },
  { id: 'Finance', name: 'Financier' },
  { id: 'Achat', name: 'Achat' },
]

export const listOrdersType = [
  { id: 'paid', name: 'Payée' },
  { id: 'to_account_not_paid', name: 'Porté au compte non payé' },
  { id: 'to_account_paid', name: 'Porté au compte payé' },
  { id: 'waiting_payment', name: 'En attente de paiement' },
  { id: 'refund', name: 'Remboursée' },
  { id: 'partialy_refund', name: 'Remboursée partiellement' },
  { id: 'bank_transfer_not_paid', name: 'Transfert bancaire non payé' },
  { id: 'bank_transfer_paid', name: 'Transfert bancaire payé' },
  { id: 'free', name: 'Gratuit' },
]


export enum LocalStorageKeys {
  OpenItemsSideBar = 'openItemsSideBar',
  ActiveEnterprise = 'activeEnterprise',
  ActiveRegion = 'activeRegion',
  CartItem = 'cartItem'
}
export const uniqueKey = Symbol('uniqueKey')
