import CustomTabs from '../../../components/common/CustomTabs'
import Title from '../../../components/common/title'
import useSelectedItemSideBar from '../../../hooks/useSelectedItemSideBar'
import DocumentsAcq from './components/documentsAcq'
import DocumentsEntreprise from './components/documentsEntreprise'

const Documents = () => {
  useSelectedItemSideBar('entreprise.documents')
  return (
    <>
      <Title>Documents</Title>
      <CustomTabs
        list={[
          {
            label: 'Documents entreprise',
            element: <DocumentsEntreprise />,
            width: '255px',
          },
          {
            label: 'Documents ACQ',
            element: <DocumentsAcq />,
            width: '219px',
          },
        ]}
      />
    </>
  )
}

export default Documents
